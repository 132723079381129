/*date-pixker styles*/
.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: rgb(255, 255, 255);
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 5px;
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
  border-top: 1px solid rgb(255, 255, 255);
}

.rmdp-container .custom-calendar.ep-arrow[direction="top"] {
  display: none;
}
.rmdp-week {
  gap: 40px;
}

.rmdp-week-day {
  color: #5a5a5c;
  cursor: default;
  font-size: 12px;
  font-weight: 500;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #757aee;
  box-shadow: 0 0 3px #8798ad;
  color: #fff;
}

.rmdp-header-values {
  color: #2c2c2c;
  margin: auto;
  font-weight: 700;
  font-size: 15px;
  font-family: sans-serif;
}
.rmdp-day span {
  border-radius: 50%;
  bottom: 1px;
  font-size: 12px;
  left: 1px;
  position: absolute;
  right: 1px;
  top: 1px;
}

.rmdp-arrow-container {
  border-radius: 20%;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  margin: 0 5px;
  width: 20px;
}

.rmdp-arrow {
  border: solid #757aee;
  border-width: 0 2px 2px 0;
  display: inline-block;
  height: 5px;
  margin-top: 5px;
  padding: 2px;
  width: 5px;
}
.rmdp-arrow-container:hover {
  background: #757aee;
  color: #000;
  box-shadow: none;
  padding: 5px;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid #fff;
  border-width: 0 2px 2px 0;
}

.rmdp-arrow-container.disabled {
  cursor: default;
}

.rmdp-arrow-container.disabled:hover {
  background-color: inherit;
  box-shadow: inherit;
}

.rmdp-arrow-container.disabled .rmdp-arrow,
.rmdp-arrow-container.disabled:hover .rmdp-arrow {
  border: solid gray;
  border-width: 0 2px 2px 0;
}
.rmdp-input{
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  
}
.rmdp-day.rmdp-today span {
  background-color: #d7d7d7;
  color: #000000;
}
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #757aee;
  color: #fff;
}
/*  */
/* .rmdp-input {
  width: 658px;
  max-width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box; Include padding and border in the width
} */
