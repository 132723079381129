  .dateInputWrapper {
    position: relative;
  }
  
  .datePlaceholder {
    position: absolute;
    top: 29px; 
    left: 14px; 
    color: #000000;
    font-weight: 500;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  .boldPlaceholder::placeholder {
    font-weight: bold;
    color: transparent; 
  }
  
  .emptyDate:not(:focus)::-webkit-datetime-edit {
    color: transparent;
  }
  
  .emptyDate:focus::-webkit-datetime-edit,
  .emptyDate:focus::-webkit-datetime-edit-text,
  .emptyDate:focus::-webkit-datetime-edit-month-field,
  .emptyDate:focus::-webkit-datetime-edit-day-field,
  .emptyDate:focus::-webkit-datetime-edit-year-field {
    color: inherit; 
  }
  
  .emptyDate:not(:focus)::-ms-value,
  .emptyDate:not(:focus)::-moz-placeholder {
    color: transparent; 
  }
  
  .emptyDate:focus::-ms-value,
  .emptyDate:focus::-moz-placeholder {
    color: inherit; 
  }
  
  .hiddenLabel {
    opacity: 0;
  }
  
  .visibleLabel {
    opacity: 1;
  }
  